import React, {useContext} from 'react';

import Context from './context.jsx';

import states from '../../assets/states.json';



export default function LocationPage() {
    const {initialPathname, selectedState, setSelectedState, geoPosition, history} = useContext(Context);

    function changeSelectedState(regionState) {
        if (selectedState) history.push('/');
        else history.push(initialPathname);
        setSelectedState(regionState);
    }

    return <div className="container w-full lgplus:w-3/5 mx-auto py-24 text-center">
        <h1 className="mb-4 text-3xl md:text-4xl font-bold">Where are you?</h1>

        <div className="mx-auto">

            { states.map( state => {
                return <button key={`state_${state.abbreviation}`} className={`px-2 py-1.5 m-1 border border-light text-base rounded-full hover:bg-primary hover:border-primary hover:text-white hover:shadow ${String(selectedState.abbreviation).toLowerCase() === state.abbreviation.toLowerCase() ? ' bg-primary text-white border-primary' : ''}`}
                    onClick={() => changeSelectedState(state)}>
                    {state.name}
                </button>
            } ) }

        </div>

        { ['deniedGeolocation', 'geolocationTimeout', 'geolocationNotSupported', 'error'].includes(geoPosition) ? <p className="inline-block md:w-1/4 mt-4 text-red-600">
            Please enable location for an improved experience. We do not collect nor sell any user data.
        </p> : <></> }
    </div>;
}

import React from 'react'

import {Link} from 'react-router-dom';



export default function() {
    const today = new Date();

    return <footer className="bg-dark w-full md:py-16 px-4">
        <div className="container mx-auto text-center">
            <div className="py-4 text-xs text-light">
                <Link to="/faqs" className="uppercase hover:text-primary-light">
                    faq
                </Link>
                <span> &nbsp;|&nbsp; </span>
                <a href="https://bybe.com/terms-and-conditions.html" className="py-3 text-light hover:text-primary-light transition duration-300">Terms & Conditions</a> 
            </div> 
            <div className="py-4 text-xs text-light">
                All rights reserved &copy;{today.getFullYear()} BYBE, INC
            </div>
        </div>
    </footer>;
}
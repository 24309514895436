import React, {useContext, useState, useEffect, useRef} from 'react';
import {Route, Switch} from 'react-router-dom';

import Context from './context.jsx';

import Header from './header.jsx';
import Footer from './footer.jsx';
import OffersPage from './offersPage.jsx';
import OfferPage from './offerPage.jsx';
import LocationPage from './locationPage.jsx';
import FaqsPage from './faqsPage.jsx';
import AgePage from './agePage.jsx';
import TutorialModal from './tutorialModal.jsx';



export default function App() {
	const {location} = useContext(Context);
	const {retailers, brands} = useContext(Context);

	const [parallaxImage, setParallaxImage] = useState(false);
	const [parallaxVideo, setParallaxVideo] = useState(false);
	const [parallaxVideoPoster, setParallaxVideoPoster] = useState(false);
	const [parallaxInnerContent, setParallaxInnerContent] = useState(false);
	const [tutorialModalOpen, setTutorialModalOpen] = useState(false); 

	const parallaxElement = useRef();

	useEffect(() => {
		parallaxElement.current.scroll(0, 0);
	}, [location.pathname]);

	return <>
		<Header />

		<TutorialModal open={tutorialModalOpen} retailers={retailers} brands={brands}
			close={() => setTutorialModalOpen(false)}
		/>

		<div ref={parallaxElement} className={ `h-full font-primary${parallaxImage || parallaxVideo ? ' parallax' : ''}` }>

			{ parallaxVideo ? <div className="parallax-layer-background absolute h-1/2 pt-10 inset-0 left-negative-6px">
				<div  className="h-1/2 flex items-center justify-center">
					<video className="absolute object-cover object-center 3xl:w-full 3xl:h-full max-w-165-percent lgplus:max-w-full filter blur-sm" poster={parallaxVideoPoster} autoPlay muted loop>
						<source src={parallaxVideo} type="video/mp4" />
					</video>

					{ parallaxInnerContent ? parallaxInnerContent : <></> }
				</div>
			</div> : <></> }

			{ parallaxImage ? <div className="parallax-layer-background absolute pt-8 inset-0 left-negative-6px">
				<div className="h-1/2 flex items-center justify-center">
					<div className="absolute w-full h-full bg-no-repeat bg-center bg-85-percent md:bg-50-percent" style={{backgroundImage: `url(${parallaxImage})`}}></div>

					{ parallaxInnerContent ? parallaxInnerContent : <></> }
				</div>
			</div> : <></> }
			
			<div className={`flex flex-col${parallaxImage || parallaxVideo ? ' parallax-layer-content absolute h-1/2 top-1/2 right-0 bottom-0 left-0' : ' h-full'}`}>
				<div style={{boxShadow: '0 -1px 3px 0 rgb(0 0 0 / 0.1), 0 -1px 2px -1px rgb(0 0 0 / 0.1)'}} className="flex-grow bg-white px-4">
					<Switch>
						<Route exact path='/' render={ props => <OffersPage setParallaxVideo={setParallaxVideo}
							setParallaxVideoPoster={setParallaxVideoPoster}
							setParallaxInnerContent={setParallaxInnerContent} 
							setTutorialModalOpen={setTutorialModalOpen} /> } />

						<Route path='/offer/:id' render={ props => <OfferPage setParallaxImage={setParallaxImage} /> } />

						<Route path='/location' render={ props => <LocationPage /> } />
						<Route path='/faqs' render={props => <FaqsPage />} />
						<Route path='/age' render={ props => <AgePage /> } />
					</Switch>
				</div>

				<Footer />
			</div>

		</div>
	</>;
};
import React, {useState} from 'react'
import Arrow from '../icons/arrow'

const Accordion = (props) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <div className='w-full text-sm overflow-hidden'>
      <label
        onClick={() => setExpanded(!expanded)}
        className='block p-2 border border-gray-200 cursor-pointer'>
        {expanded ? <Arrow direction='down' /> : <Arrow direction='right' />}
        <span className=''>{props.header}</span>
      </label>

      {expanded ? (
        <div className='border-b border-r border-gray-200'>
          <div className='overflow-hidden border-l-2 border-primary'>
            <div className='p-2'>{props.children}</div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default Accordion

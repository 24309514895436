import React from 'react'

const Arrow = ({
  type = 'single',
  direction = 'up',
  color,
  extraClasses,
  size = '6',
}) => {
  let svgPath

  if (type.toLowerCase() == 'single') {
    switch (direction.toLowerCase()) {
      case 'left':
        svgPath = 'M15.75 19.5L8.25 12l7.5-7.5'
        break
      case 'right':
        svgPath = 'M8.25 4.5l7.5 7.5-7.5 7.5'
        break
      case 'up':
        svgPath = 'M4.5 15.75l7.5-7.5 7.5 7.5'
        break
      case 'down':
        svgPath = 'M19.5 8.25l-7.5 7.5-7.5-7.5'
        break
      default:
        svgPath = ''
    }
  } else if (type.toLowerCase() == 'double') {
    switch (direction.toLowerCase()) {
      case 'left':
        svgPath = 'M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5'
        break
      case 'right':
        svgPath = 'M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5'
        break
      case 'up':
        svgPath = 'M4.5 12.75l7.5-7.5 7.5 7.5m-15 6l7.5-7.5 7.5 7.5'
        break
      case 'down':
        svgPath = 'M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5'
        break
      default:
        svgPath = ''
    }
  }

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth={1.5}
      stroke='currentColor'
      className={`inline-block w-${size} h-${size} ${color} ${extraClasses}`}>
      <path strokeLinecap='round' strokeLinejoin='round' d={svgPath} />
    </svg>
  )
}

export default Arrow

import React, {useContext} from 'react';

import Context from './context.jsx';

import LoadingSpinner from '../shared/utility/loadingSpinner';
import Accordion from '../shared/utility/accordion';



export default function FaqsPage() {
    const {faqs} = useContext(Context);

    return <div className='container md:w-1/2 py-24 mx-auto'>
        <h1 className='text-2xl text-center pb-7'>Frequently Asked Questions</h1>
        
        {faqs.length ? faqs.map( faq => { 
            return <Accordion className="text-lg" key={ `faq_${faq.question}` } header={ `${faq.question}`}>
                {faq.answer}
            </Accordion>;
        } ): <LoadingSpinner size="12" color="text-primary" extraClasses="mx-auto" wrapperClasses="py-3" />}
    </div>
}

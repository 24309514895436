import React, {useContext} from 'react';
import {Link} from 'react-router-dom';

import Context from './context';
import InstagramIcon from '../shared/icons/instagramIcon.jsx';
import FacebookIcon from '../shared/icons/facebookIcon.jsx';


export default function Header() {
    const {selectedState} = useContext(Context);

    return <header className="fixed w-full h-16 z-10 px-4 bg-white shadow font-primary">
        <nav className="container h-full flex justify-between items-center mx-auto">
            <Link to="/">
                <img className="h-7" src="/assets/images/offers/adultBevOffersLogo.svg" alt="Adult Bev Offers Logo" />
            </Link>

            <div className='flex gap-x-5 items-center'>
                <a href="https://www.instagram.com/adultbevoffers" target="_blank"><InstagramIcon /></a>
                <a href="https://facebook.com/adultbevoffers" target="_blank"><FacebookIcon /></a>
                
                <Link to="/location" className="text-sm font-bold uppercase">
                    {selectedState.name}
                </Link>    
            </div>
        </nav>
    </header>;
}
